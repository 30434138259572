import React from "react";
import './index.css'

function Footer() {
  return (
    <footer className="footer_wrap">
      <h4>
        Copyright © {new Date().getFullYear()} 趣查车. All rights reserved.
        ICP备案号：<a href="https://beian.miit.gov.cn">浙ICP备2024090183号-2</a>
      </h4>
    </footer>
  );
}

export default Footer;
