import React from "react";
import { Modal } from "antd";
import DY from "../../assets/image/douyin.png";
import WC from "../../assets/image/wechat.jpg";
import "./index.css";

function CodeModal({ onClose }) {
  return (
      <Modal className="modal_wrap" title="" open={true} onCancel={onClose} footer={null}>
        <div className="flex_wrap">
          <div className="code_wrap">
            <img className="img" src={DY} alt="douyin" />
            <h5>抖音扫一扫</h5>
          </div>
          <div className="code_wrap">
            <img className="img" src={WC} alt="wechat" />
            <h5>微信扫一扫</h5>
          </div>
        </div>
      </Modal>
  );
}

export default CodeModal;
