import { useState } from "react";
import Header from "./components/header/index.tsx";
import Footer from "./components/footer/index.tsx";
import Modal from "./components/modal/index.tsx";
import Banner from "./assets/image/banner.jpeg";
import "./App.css";

export default function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const list = [
    {
      title: "查询便捷性",
      descript:
        "趣查车提供了一个快速、便捷的平台，用户可以随时随地通过互联网查询车辆的相关信息，包括车辆的注册信息、违章记录、年检情况等，极大地节省了用户的时间和精力。",
    },
    {
      title: "数据全面性",
      descript:
        "趣查车与权威数据平台直接对接，能够提供全面、准确的车辆信息。用户不仅可以查询到车辆的基本信息，还可以了解到车辆的详细历史记录，如事故记录、维修记录等。",
    },
    {
      title: "数据安全性",
      descript:
        "趣查车采用先进的数据加密技术，确保用户查询信息时的隐私安全。用户在查询车辆信息时，不必担心个人信息泄露的问题。",
    },
    {
      title: "实时更新性",
      descript:
        "趣查车能够实时更新车辆信息，用户可以获得最新的车辆状态，如最新的违章记录、年检情况等，确保用户能够及时了解车辆的最新情况。",
    },
  ];
  return (
    <main className="office_page">
      <Header />
      <div className="main_wrap">
        <img className="img" src={Banner} alt="Banner" />
        <div className="cover"></div>
        <div className="context">
          <h1>欢迎来到 趣查车</h1>
          <p className="flex_wrap">
            在这里，你可以快速、全面、安全、实时地查询爱车，快来试试吧👉
            <span className="start_btn" onClick={() => setIsOpen(true)}>
              开始吧
            </span>
          </p>
        </div>
      </div>
      <div className="desc_wrap">
        <div className="desc_top_wrap">
          <h2 className="">趣查车 的优点</h2>
          <p className="text">当然不止这些</p>
        </div>
        <div className="desc_bottom_wrap">
          {list.map((item) => {
            return (
              <div className="desc_list_item" key={item.title}>
                <h3>{item.title}</h3>
                <p className="item_text">{item.descript}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
      {isOpen && <Modal onClose={() => setIsOpen(false)} />}
    </main>
  );
}
