import React from "react";
import './index.css'

function Header() {
  return (
    <nav className="header_wrap">
      <span
        className="home_tab"
      >
        首页
      </span>
    </nav>
  );
}

export default Header;
